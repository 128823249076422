@tailwind base;
@tailwind components;
@tailwind utilities;

.nav-li {
  color: #ffffff;
}

.nav-li:hover {
  color: #ffd700;
  cursor: pointer;
}

.siwa-signin-button {
  border-radius: 30px !important;
}

.form-input {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14.5px 16px;
  gap: 8px;

  height: 48px;

  background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0.2),
      rgba(217, 217, 217, 0.2)
    ),
    #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  /* Inside auto layout */
  align-self: stretch;
}

.form-listbox {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14.5px 16px;
  gap: 8px;

  height: 48px;

  background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0.2),
      rgba(217, 217, 217, 0.2)
    ),
    #ffffff;

  /* Inside auto layout */
  align-self: stretch;
}

.form-select {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 0px 10px;

  height: 48px;

  background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0.2),
      rgba(217, 217, 217, 0.2)
    ),
    #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  /* Inside auto layout */
  align-self: stretch;
}

.form-textarea {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14.5px 16px;
  gap: 8px;

  background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0.2),
      rgba(217, 217, 217, 0.2)
    ),
    #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  /* Inside auto layout */
  align-self: stretch;
}

.form-label {
  font-size: 14px;
  font-weight: 700;
  color: #555555;
}

.retailer-page-bg {
  /* border: 5px solid red; */
  background-size: auto;
  background-repeat: no-repeat;
  filter: blur(160px);
  -webkit-filter: blur(160px);
  -webkit-perspective: 1000;
  perspective: 1000;
  backface-visibility: hidden;
}

.searchbar-results-bg {
  background: url(/topo.png), rgba(255, 255, 255, 0.83);
  backdrop-filter: blur(160px);
  -webkit-backdrop-filter: blur(160px);
  -webkit-perspective: 1000;
  perspective: 1000;
  backface-visibility: hidden;
}

// when the browser is mobile sized, searchbar-results-bg should just be white
@media (max-width: 640px) {
  .searchbar-results-bg {
    background: rgba(255, 255, 255, 0.93);
  }
}

@supports (background: - webkit-named-image(i)) {
  .retailer-page-bg {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform: translateZ(0);
    transform: translate3d(0, 0, 0);
    transform: translateZ(0);
  }
}

@supports not (
  (not (-moz-appearance: button)) and (contain: paint) and
    (-webkit-appearance: none)
) {
  .retailer-page-bg {
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform: translateZ(0);
    transform: translate3d(0, 0, 0);
    transform: translateZ(0);
  }
}

.image-gradient {
  mask-image: linear-gradient(
    0,
    rgba(0, 0, 0, 0.8) 15.8%,
    rgba(0, 0, 0, 0.8) 100%
  );
  -webkit-mask-image: linear-gradient(
    0,
    rgba(0, 0, 0, 0.8) 15.8%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.invisible-scrollbar::-webkit-scrollbar {
  width: 0px;
}

.invisible-scrollbar {
  overflow: scroll;
}

.invisble-scrollbar::-webkit-scrollbar {
  background: transparent;
  /* make scrollbar transparent */
  width: 0px;
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  // -webkit-flex-grow: 1;
  // flex-grow: 1;
  font-family: "Roboto", arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}
